<template>
    <div name="modal" class="modal modal-retorno" :class="[modalAtivado]">
        <div class="modal-mask" v-if="this.modal.modalAtivado"></div>
        <div class="modal-wrapper">
            <img class="modal-close" @click="fecharModal();" src="@/assets/images/icones/close.svg" />
            <div class="modal-container" :class="[modalAtivado]">
                <img class="imagem-retorno" :src="tipoModal" alt="Icone">
                <div class="modal-body">
                    <h3 class="titulo" v-html="modal.tituloModal"></h3>
                    <div class="text-center corpo" v-html="modal.mensagemModal"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import error from '@/assets/images/icones/error.svg'
import sucesso from '@/assets/images/icones/sucesso.svg'
export default {
    data() {
        return {
            icons: {
                'sucesso': sucesso,
                'error': error
            }
        };
    },
    methods: {
        fecharModal() {
            this.$store.commit("interacoes/FECHAR_MODAL_RETORNO", false);
        },
    },
    computed: {
        modal() {
            return this.$store.state.interacoes.modalRetorno;
        },
        modalAtivado() {
            return this.modal.modalAtivado ? 'ativo' : '';
        },
        tipoModal() {
            return this.icons[this.modal.tipoModal];
        }
    },
};
</script>
  